/*=============================================
=            23. progress Bar Css           =
==============================================*/
.progress-bar {
    &--one {
        & .progress-charts {
            margin-bottom: 20px;
            padding-bottom: 20px;
            overflow: hidden;
        }
    }
    &--two {
        & .progress-charts {
            padding-bottom: 20px;
            overflow: hidden;
        }
    }
}
.progress-charts {
    & .heading {
        color: #6d70a6;
        margin-bottom: 12px;
        font-weight: 400;
    }
    
    & .progress  {
        height: 4px;
        overflow: visible;
        font-size: 14px;
        background-color: #f5f5f5;
        border-radius: 0;
        
        & .progress-bar {
            position: relative;
            background: $theme-color--default;
            color: $theme-color--default;

            &::after,&::before {
                content: '';
                position: absolute;
                right: -4px;
                top: 50%;
                border-radius: 50%;
                transform: translate(50%, -50%);

            }

            &::after {
                height: 13px;
                width: 13px;
                border: 3px solid currentColor;

            }

            &::before {
                height: 28px;
                width: 28px;
                border: 1px solid currentColor;
                opacity: .3;
            }

            & span.percent-label {
                position: absolute;
                right: -45px;
                font-size: 14px;
                font-weight: 500;
                top: -40px;
                color: #2e3280;
                line-height: 2;
            }

            &.gradient-1 {
                color: rgb(97, 253, 237);
                background-color: rgb(13, 138, 188);
                background-image: linear-gradient(-224deg, rgb(13, 138, 188) 0px, rgb(97, 253, 237) 100%);
            }
            &.gradient-2 {
                color: rgb(238, 206, 144);
                background-color: rgb(212, 85, 41);
                background-image: linear-gradient(-224deg, rgb(212, 85, 41) 0px, rgb(238, 206, 144) 100%);
            }
            &.gradient-3 {
                color: rgb(92, 81, 255);
                background-color: rgb(246, 70, 169);
                background-image: linear-gradient(-224deg, rgb(246, 70, 169) 0px, rgb(92, 81, 255) 100%);
            }
            &.gradient-4 {
                color: rgb(229, 82, 154);
                background-color: rgb(231, 118, 84);
                background-image: linear-gradient(-224deg, rgb(231, 118, 84) 0px, rgb(229, 82, 154) 100%);
            }
        }
        
        &__two {
            & .progress  {
                height: 16px;
                overflow: visible;
                font-size: 14px;
                border-radius: 0;
                height: 32px;
                padding: 6px;
                border-radius: 30px;
                box-shadow: none;
                background-color: #f2f2f2; 

            }
            & .progress-bar {
                background-color: #9C7AF2;
                background: $theme-color--default;
                position: relative;
                top: auto;
                bottom: auto;
                left: auto;
                right: auto;
                border-radius: inherit;
                
                &::after,&::before {
                    display: none;
                }
                
                & span.percent-label  {
                    top: 50%;
                    transform: translateY(-50%);
                    right: 15px;
                    display: block;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                }
            }
            .progress {
               &.gradient-1 {
                    color: rgb(97, 253, 237);
                    background-color: rgb(13, 138, 188);
                    background-image: linear-gradient(-224deg, rgb(13, 138, 188) 0px, rgb(97, 253, 237) 100%);
                }
                &.gradient-2 {
                    color: rgb(238, 206, 144);
                    background-color: rgb(212, 85, 41);
                    background-image: linear-gradient(-224deg, rgb(212, 85, 41) 0px, rgb(238, 206, 144) 100%);
                }
                &.gradient-3 {
                    color: rgb(92, 81, 255);
                    background-color: rgb(246, 70, 169);
                    background-image: linear-gradient(-224deg, rgb(246, 70, 169) 0px, rgb(92, 81, 255) 100%);
                }
                &.gradient-4 {
                    color: rgb(229, 82, 154);
                    background-color: rgb(231, 118, 84);
                    background-image: linear-gradient(-224deg, rgb(231, 118, 84) 0px, rgb(229, 82, 154) 100%);
                } 
            }
            
        }
        
    }
}