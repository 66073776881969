/*=============================================
=              13. Box Icon Css              =
=============================================*/
.ht-box-icon {
    &.style-01 {
        & .icon-box-wrap {
            padding: 36px 10px 26px;
            border-radius: 5px;
            background: #fff;
            border: 2px solid #eee;
            transition: $transition--default;
            text-align: center;
            margin-top: 30px;
            & .icon {
                height: 65px;
                text-align: center;
                width: 65px;
                margin: auto;
                margin-bottom: 29px;
                color: $theme-color--default;
                svg {
                    & > * {
                      stroke: $theme-color--default; 
                    }
                }
            }
            
            & .content {
                text-align: center;
                & .heading {
                    font-weight: 500;
                    line-height: 1.25;
                    margin-bottom: 12px;
                }
                & .service_text{
                    color: $body-text-color;
                }
            }
        }
        &:hover {
            .icon-box-wrap {
                border-color: #fff;
                transform: translateY(-5px);
                background: #fff;
                box-shadow: 0 0 40px rgba(51,51,51,.1);
            }
        }
        & .feature-btn {
            & a {
                color: $theme-color--default;
                margin-top: 20px;
                &:hover {
                    color: $theme-color--two;
                }
            }
        }
    }
    &.style-02 {
        & .icon-box-wrap {
            padding: 30px 15px;
            border-radius: 5px;
            transition: $transition--default;
            text-align: center;
            display: flex;
            & .icon {
                min-width: 80px;
                margin-right: 10px;
                color: $theme-color--black;
                .svg-icon {
                    width: 65px;
                    height: 65px;
                }
                svg * {
                    stroke: currentColor;
                }
            }
            
            & .content {
                text-align: left;
                & .heading {
                    font-weight: 500;
                    line-height: 1.25;
                    margin-bottom: 12px;
                }
                & .service_text{
                    color: $body-text-color;
                }
            }
        }
        &:hover {
            .icon-box-wrap {
                border-color: #fff;
                transform: translateY(-5px);
                background: #fff;
                box-shadow: 0 0 40px rgba(51,51,51,.1);
            }
        }
        & .feature-btn {
            & a {
                color: $theme-color--default;
                margin-top: 20px;
                &:hover {
                    color: $theme-color--two;
                }
            }
        }
    }
    &.style-03 {
        & .icon-box-wrap {
            padding: 30px;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
            & .content-header {
                display: flex;
                align-items: center;
                & .icon {
                    flex-shrink: 0;
                    font-size: 48px;
                    font-weight: 48px;
                    min-width: 60px;
                    margin-right: 10px;
                    color: #d2a98e;
                }
                & .heading{
                    color: $theme-color--default;
                    margin-bottom: 10px;
                }
            }
            
            & .content {
                & .service_text{
                    color: $body-text-color;
                }
            }
        }
        &:hover {
            .icon-box-wrap {
                transform: translateY(-5px);
                background: #fff;
                box-shadow: 0 0 40px rgba(51,51,51,.1);
            }
        }
        @media #{$small-mobile}{
            & .icon-box-wrap {
                padding: 37px 10px;
                & .image {
                    height: 100px;
                    width: 100px;
                    line-height: 100px;
                }
            }
           
        }
    }
}
.feature-list-button-box {
    & .ht-btn {
        margin: 10px;
    }
}
