/*=======================================
=       41. Single Smart Vision Css         =
========================================*/

.vision-content {
    & .heading {
        font-size: 28px;
        line-height: 1.43;
        font-weight: 400;
        color: #002fa6;
    }
}
.portfolio-details-table {
    & table {
        margin: 0 0 1.5em;
        margin-bottom: 1.5em;
        width: 100%;
        display: inline-table;
        overflow: hidden;
    }
    & .label {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
        color: #333;
        margin-bottom: 12px;
    }
    & td:last-child {
        text-align: right;
        padding-right: 0;
    }
    & td {
        border: 0;
        background: none !important;
        padding: 17px 10px;
        padding-right: 10px;
        text-align: left;
        &:first-child {
            padding-left: 0;
        }
    }
    & tr + tr {
        border-top: 1px solid #ededed;
    }
    
    & .portfolio-details-share a {
        padding: 0 10px;
        font-size: 15px;
        color: #ababab;
    }
}

.ht-problem-solution {
    .item {
        border-bottom: 1px solid #eee;
        padding-bottom: 50px;
        margin-bottom: 44px;
        .problem {
            position: relative;
            padding-left: 32px;
            font-size: 15px;
            font-weight: 500;
            line-height: 1.74;
            margin-bottom: 20px;
            &::before {
                color: #086ad8;
                content: '\f178';
                position: absolute;
                top: 4px;
                left: 0;
                font-size: 18px;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 400;
            }
        }
        .solution {
            position: relative;
            padding-left: 32px;
            &::before {
                color: #086ad8;
                content: '\f00c';
                position: absolute;
                top: 4px;
                left: 0;
                font-size: 18px;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 400;
            }
        }
    }
}


.results-wrap {
    & .text {
        font-size: 24px;
    }
}


.entry-portfolio-return-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
    background: #f6f2ed;
    box-shadow: 0 4px 32px rgba(51, 51, 51, 0.07);
    font-size: 18px;
    &:hover {
        background: $theme-color--default;
        color: $white;
    }
}
.portfolio-nav-links .nav-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    
    @media #{$large-mobile}{
        grid-template-columns: auto;
    }
    
    & .next {
        text-align: right;
    }
    & .inner div {
        display: flex;
        align-items: center;
        min-height: 190px;
        padding: 20px 0;
    }
    & .prev img {
        margin-right: 28px;
    }
    & .next img {
        margin-left: 28px;
        order: 2;
    }
    & .next a {
        padding-right: 30px;
    }
    & .prev a {
        padding-left: 30px;
    }
    & .inner h6::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 300;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
    }
    & .prev h6::before {
        left: 15px;
        content: '\f104';
    }
    & .next h6::before {
        right: 15px;
        content: '\f105';
    }
}